// import { api } from "roboflex-thalamus-request-handler/dist"

export default class IndicadoresDatasource {
    async getCycleTimeData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.cycleTimeData;

        return rawData;
    }

    async getCycleTimePerProductData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.cycleTimePerProductData;

        return rawData;
    }

    async getProductionData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.productionData;

        return rawData;
    }

    async getWasteData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.wasteData;

        return rawData;
    }
}

class MockData {
    static cycleTimeData = [
        {
            tempo_ciclo: 28.2,
            data: "05-02-2024",
        },
        {
            tempo_ciclo: 55.8,
            data: "06-02-2024",
        },
        {
            tempo_ciclo: 16.5,
            data: "07-02-2024",
        },
        {
            tempo_ciclo: 42.1,
            data: "08-02-2024",
        },
        {
            tempo_ciclo: 33.2,
            data: "09-02-2024",
        },
        {
            tempo_ciclo: 11.0,
            data: "10-02-2024",
        },
    ];

    static cycleTimePerProductData = [
        {
            produto: "Braço XS",
            tempo_ciclo: 17.2,
        },
        {
            produto: "Fundo case X115",
            tempo_ciclo: 34.8,
        
        },
    ];
    static productionData = [
        {
            produto: "Braço XS",
            quantidade: 452,
        },
        {
            produto: "Fundo case X115",
            quantidade: 133,
        },
    ];
    static wasteData = [
        {
            produto: "Braço XS",
            borra_qt: 12.1,
            refugo_qt: 0.54,
        },
        {
            produto: "Fundo case X115",
            borra_qt: 3.51,
            refugo_qt: 1.25,
        },
    ];
}
