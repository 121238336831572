import { createRouter, createWebHistory } from "vue-router";
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissaoService";

import InjetoraDashboard from "@/view/InjetoraDashboard.vue";
import PainelEdicao from "@/view/PainelEdicao.vue";
import PlanejamentoInjecao from "@/view/PlanejamentoInjecao.vue";
import IndicadoresInjecao from "@/view/indicadores/PainelIndicadores.vue";
import CadastroParametros from "@/view/CadastroParametros.vue";

export class RoutesName {
    static injetoraDashboard = "injetora-dashboard";
    static painelEdicao = "painel-edicao";
    static planejamentoInjecao = "planejamento-injecao";
    static indicadoresInjecao = "painel-indicadores";
    static cadastroParametros = "cadastro-parametros";
}

function sendUserToPortal() {
    const buildMode = process.env.NODE_ENV;
    if (buildMode == "production") {
        window.location.href = "https://portal.thalamus.ind.br/#/";
    } else {
        window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
    }
}

// valida se esta logado
async function guardMyroute(to, from, next) {
    // if (sso.validarSessao() && await hasPermission()) {
    if (sso.validarSessao()) {
        next();
    } else {
        sendUserToPortal();
    }
}

async function hasPermission(id) {
    const permissoes = await getPermissao();
    // Id da funcionalidade necessária pra acessar o módulo como um todo
    const funcionalidadeNecessaria = id;
    return permissoes.includes(funcionalidadeNecessaria);
}

const routes = [
    {
        path: "/",
        name: RoutesName.injetoraDashboard,
        component: InjetoraDashboard,
    },
    {
        path: "/painel-edicao",
        name: RoutesName.painelEdicao,
        component: PainelEdicao,
        beforeEnter: () => hasPermission(62),
    },
    {
        path: "/planejamento-injecao",
        name: RoutesName.planejamentoInjecao,
        component: PlanejamentoInjecao,
    },
    {
        path: "/painel-indicadores",
        name: RoutesName.indicadoresInjecao,
        component: IndicadoresInjecao,
    },
    {
        path: "/cadastro-parametros",
        name: RoutesName.cadastroParametros,
        component: CadastroParametros,
    },
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes,
});

router.beforeEach(guardMyroute);

export default router;
