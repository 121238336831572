import { api } from "roboflex-thalamus-request-handler/dist";
import { InjectionOrderModel } from "../model/InjectionOrderModel";
import { InjectionActivityModel } from "../model/InjectionActivityModel";
import { InjectionServiceTypeModel } from "../model/InjectionServiceTypeModel";
import { InjectionMachineModel } from "../model/InjectionMachineModel";
import { EmployeeModel } from "../model/EmployeeModel";
import { userLoggedIn } from "@/core/consts";

export class EditarApontamentoDatasource {
  async getActiveOps() {
    let response = await api.get("ordem-producao/injecao/existente");
    let convertedData = [];

    for (var ele in response.data) {
      convertedData.push(InjectionOrderModel.fromJson(response.data[ele]));
    }
    return convertedData;
  }

  async getOps() {
    let response = await api.get("ordem-producao/injecao/pendente");
    let convertedData = [];

    for (var ele in response.data.data) {
      convertedData.push(InjectionOrderModel.fromJson(response.data.data[ele]));
    }
    return convertedData;
  }

  async getOpDetails(opId) {
    let response = await api.get(`ordem-producao/injecao/buscar/${opId}`);
    let convertedData = InjectionOrderModel.fromJson(response.data);
    return convertedData;
  }

  async getActivities(opId) {
    let response = await api.get(`ordem-producao/trabalho/listar/${opId}`);
    let convertedData = [];

    for (var ele in response.data) {
      convertedData.push(InjectionActivityModel.fromJson(response.data[ele]));
    }

    return convertedData.reverse();
  }

  async getServicesType() {
    let response = await api.get("injecao/servico");
    let convertedData = [];

    for (let ele of response.data.data) {
      convertedData.push(InjectionServiceTypeModel.fromJson(ele));
    }

    return convertedData;
  }

  async getMachines() {
    let response = await api.get("injecao/maquina/listar");
    let convertedData = [];

    for (let ele of response.data) {
      convertedData.push(InjectionMachineModel.fromJson(ele));
    }

    return convertedData;
  }

  async getInjetionEmployees() {
    let setor = { setor: [{ valor: 56 }, { valor: 38 }] };

    let response = await api.post("pessoa/setor", setor);
    let convertedData = [];

    for (let ele of response.data) {
      convertedData.push(EmployeeModel.fromJson(ele));
    }

    return convertedData;
  }

  async updateField(activity) {
    try {
      let response = await api.post(`trabalho/correcao/${activity.id}`, activity.toRemote());

      return InjectionActivityModel.fromJson(response);
    } catch (e) {
      console.log(`error: ${e}`);
      return Error(e);
    }
  }

  async finishOp(opId) {
    try {
      let response = await api.post(`injecao/op/encerrar`, { op_cod: opId, pessoa_id: userLoggedIn.id });
      return response.data.msg;
    } catch (e) {
      console.log(e.response.data);
      return e.response.data.msg;
    }
  }

  async reopenOp(opId) {
    try {
      console.log(typeof(opId))
      let response = await api.post(`injecao/op/reabrir`, { op_cod: parseInt(opId), pessoa_id: userLoggedIn.id });
      return response.data.msg;
    } catch (e) {
      console.log(e.response.data);
      return e.response.data.msg;
    }
  }
}
