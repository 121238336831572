// import { api } from "roboflex-thalamus-request-handler/dist"

export default class IndicadoresDatasource {
    async getCycleTimeData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.cycleTimeData;

        return rawData;
    }

    async getProductionData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.productionData;

        return rawData;
    }

    async getWasteData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.wasteData;

        return rawData;
    }
}

class MockData {
    static cycleTimeData = [
        {
            tempo_ciclo: 40.2,
            data: "05-02-2024",
        },
        {
            tempo_ciclo: 37.8,
            data: "06-02-2024",
        },
        {
            tempo_ciclo: 43.5,
            data: "07-02-2024",
        },
        // {
        //     tempo_ciclo: 42.1,
        //     data: "08-02-2024",
        // },
        // {
        //     tempo_ciclo: 33.2,
        //     data: "09-02-2024",
        // },
        // {
        //     tempo_ciclo: 39.0,
        //     data: "10-02-2024",
        // },
    ];
    static productionData = [
        {
            data: "05-02-2024",
            quantidade: 322,
        },
        {
            data: "06-02-2024",
            quantidade: 548,
        },
        {
            data: "07-02-2024",
            quantidade: 1500,
        },
        // {
        //     data: "08-02-2024",
        //     quantidade: 677,
        // },
        // {
        //     data: "09-02-2024",
        //     quantidade: 352,
        // },
        // {
        //     data: "10-02-2024",
        //     quantidade: 547,
        // },
    ];
    static wasteData = [
        {
            data: "05-02-2024",
            borra_qt: 12.1,
            refugo_qt: 0.54,
        },
        {
            data: "06-02-2024",
            borra_qt: 3.51,
            refugo_qt: 1.25,
        },
        {
            data: "07-02-2024",
            borra_qt: 2.01,
            refugo_qt: 0.5,
        },
        // {
        //     data: "08-02-2024",
        //     borra_qt: 5.68,
        //     refugo_qt: 9.7,
        // },
        // {
        //     data: "09-02-2024",
        //     borra_qt: 9.4,
        //     refugo_qt: 8.7,
        // },
        // {
        //     data: "10-02-2024",
        //     borra_qt: 0.12,
        //     refugo_qt: 1.05,
        // },
    ];
}
