<template>
    <td class="clicavel" :tabindex="index" @click="toggleEdit">
        <span v-if="!isEditing">{{ displayValue }}</span>
        <input v-if="isEditing" :type="inputType" v-model="localValue" @keydown.enter="loseFocus" required />
        <slot></slot>
    </td>
</template>

<script>
export default {
    props: {
        value: [String, Number, Date, Array],
        inputType: { type: String, default: "text" },
        displayValue: [String, Number, Date],
        index: Number,
    },
    data() {
        return {
            isEditing: false,
            localValue: this.value, // Use local data property to handle editing
        };
    },
    // watch: {
    //   value(newVal) {
    //     if(this.localValue == newVal) return;
    //     console.log("att")
    //     this.localValue = newVal; // Update local value when prop changes
    //   },
    // },
    methods: {
        toggleEdit() {
            this.isEditing = true;
        },
        loseFocus() {
            this.isEditing = false;
            // console.log("foco perdido");

            /// Verifica se o valor do input é o mesmo que já estava, caso sim, return
            if (this.localValue == this.value) return;

            /// Verifica se o valor do input é invalido (NaN) E se é um número OU se é negativo OU se é vazío E do tipo number
            /// Caso seja, o valor do input recebe 0
            if (
                (isNaN(this.localValue) && this.localValue instanceof Number) ||
                this.localValue < 0 ||
                (this.localValue == "" && this.inputType == "number")
            ) {
                this.localValue = 0;
            }
            this.$emit("update:value", this.localValue); // Emit valor para atualizar componente pai
        },
    },
};
</script>
