// import { api } from "roboflex-thalamus-request-handler/dist"

export default class IndicadoresDatasource {
    async getCycleTimeData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.cycleTimeData;

        return rawData;
    }

    async getCycleTimePerProductData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.cycleTimePerProductData;

        return rawData;
    }

    async getProductionData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.productionData;

        return rawData;
    }

    async getWasteData() {
        // async getData(startDt, endDt, filter) {
        // let content = {dt_inicio: startDt, dt_fim: endDt}
        // let rawData = await api.post('ENDPOINT', content)
        let rawData = MockData.wasteData;

        return rawData;
    }
}

class MockData {
    static cycleTimeData = [
        {
            tempo_ciclo: 28.2,
            data: "05-02-2024",
        },
        {
            tempo_ciclo: 32.8,
            data: "06-02-2024",
        },
        {
            tempo_ciclo: 25.5,
            data: "07-02-2024",
        },
        {
            tempo_ciclo: 37.8,
            data: "08-02-2024",
        },
        {
            tempo_ciclo: 33.2,
            data: "09-02-2024",
        },
        {
            tempo_ciclo: 29.0,
            data: "10-02-2024",
        },
    ];

    static cycleTimePerProductData = [
        {
            produto: "Braço XS",
            tempo_ciclo: 28.2,
        },
        {
            produto: "Fundo case X115",
            tempo_ciclo: 65.8,
        },
        {
            produto: "Base bola peq",
            tempo_ciclo: 6.5,
        },
        {
            produto: "Base quadrada",
            tempo_ciclo: 42.1,
        },
        {
            produto: "Frontal X110",
            tempo_ciclo: 33.2,
        },
        {
            produto: "Botão T220",
            tempo_ciclo: 11.0,
        },
    ];
    static productionData = [
        {
            data: "05-02-2024",
            quantidade: 452,
        },
        {
            data: "06-02-2024",
            quantidade: 133,
        },
        {
            data: "07-02-2024",
            quantidade: 885,
        },
        {
            data: "08-02-2024",
            quantidade: 174,
        },
        {
            data: "09-02-2024",
            quantidade: 220,
        },
        {
            data: "10-02-2024",
            quantidade: 546,
        },
    ];
    static wasteData = [
        {
            data: "05-02-2024",
            borra_qt: 2.7,
            refugo_qt: 0.54,
        },
        {
            data: "06-02-2024",
            borra_qt: 3.51,
            refugo_qt: 1.25,
        },
        {
            data: "07-02-2024",
            borra_qt: 2.01,
            refugo_qt: 4.5,
        },
        {
            data: "08-02-2024",
            borra_qt: 1.68,
            refugo_qt: 2.7,
        },
        {
            data: "09-02-2024",
            borra_qt: 9.4,
            refugo_qt: 1.7,
        },
        {
            data: "10-02-2024",
            borra_qt: 0.52,
            refugo_qt: 1.05,
        },
    ];
}
