<template>
    <Bar :data="chartDataProp" :options="options" />
</template>

<script>
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    components: { Bar },
    props: {
        chartDataProp: Object,
    },
    data() {
        return {
            options: {
                    plugins: {
                        datalabels: {
                            // anchor: "center",
                            // clamp: true,
                            // align: "10",
                            font: {
                                backgroundColor: "#FFFFFF",
                                weight: "bold",
                                size: 18,
                            },
                        },
                        legend: {
                            display: false,
                        },
                    },
            },
        };
    },
};
</script>
