<template>
    <section>
        <NotificationMessenger ref="notification" :message="notificationMessage" />

        <div class="titulo">
            <div class="margem container">
                <h2>Edição de apontamento</h2>
            </div>
        </div>

        <div class="margem container">
            <div class="bloco margem">
                <div class="margem container">
                    <h3>Selecione a OP</h3>
                    <select @change="getOpData($event.target.value)">
                        <option value="" selected></option>
                        <option v-for="ele in ops" :key="ele.id" :value="ele.id">
                            {{ ele.opNum }}
                        </option>
                    </select>
                </div>
                <h2>{{ injectionOrder.productCode }} - {{ injectionOrder.productName }}</h2>
                <div class="grid-4 margem">
                    <div class="bloco margem">
                        <div class="fonte-fraca">Data de entrega</div>
                        <h3>
                            {{ parseDate(injectionOrder.deliveryDate ?? null, false) }}
                        </h3>
                    </div>
                    <div class="bloco margem">
                        <div class="fonte-fraca">Quantidade prevista</div>
                        <h3>{{ injectionOrder.quantityExpected }}</h3>
                    </div>
                    <div class="bloco margem">
                        <div class="fonte-fraca">Quantidade produzida</div>
                        <h3>{{ injectionOrder.quantityProduced }}</h3>
                    </div>

                    <div class="margem bloco">
                        <div class="fonte-fraca">Borra produzida</div>
                        <h3>{{ parseFloat(injectionOrder.quantityBorra ?? 0).toFixed(2) }}Kg</h3>
                    </div>
                    <div class="margem bloco">
                        <label>Refugo produzido</label>
                        <h3>{{ parseFloat(injectionOrder.quantityRefugo ?? 0).toFixed(2) }}Kg</h3>
                    </div>
                </div>
            </div>

            <div v-if="isLoading" class="loading"><div></div></div>

            <div v-if="!isLoading" class="bloco margem">
                <table class="tabela">
                    <tbody>
                        <tr>
                            <th>Data inicio</th>
                            <th>Data fim</th>
                            <th>Tipo de trabalho</th>
                            <th>Maquina</th>
                            <th>Peças produzidas</th>
                            <th>Borra</th>
                            <th>Refugo</th>
                            <th>Equipe</th>
                        </tr>
                        <tr v-for="(activity, index) in activities" :key="activity.id">
                            <editable-cell
                                :value="activity.startDateTime"
                                :display-value="parseDate(activity.startDateTime, true)"
                                input-type="datetime-local"
                                :index="index"
                                @update:value="updateValue(activity, 'startDateTime', $event)"
                            >
                            </editable-cell>
                            <editable-cell
                                :value="activity.endDateTime"
                                :display-value="
                                    parseDate(activity.endDateTime, true) == 'Invalid Date'
                                        ? 'Em aberto'
                                        : parseDate(activity.endDateTime, true)
                                "
                                input-type="datetime-local"
                                :index="index"
                                @update:value="updateValue(activity, 'endDateTime', $event)"
                            ></editable-cell>

                            <td>
                                <select @change="(event) => updateValue(activity, 'serviceTypeId', event.target.value)">
                                    <option
                                        v-for="service of services"
                                        :key="service.id"
                                        :value="service.id"
                                        :selected="activity.serviceTypeId === service.id"
                                        v-on:select="updateValue(activity, 'serviceTypeId', $event)"
                                    >
                                        {{ getServiceType(service.id) }}
                                    </option>
                                </select>
                            </td>

                            <td>
                                <select @change="(event) => updateValue(activity, 'machineId', event.target.value)">
                                    <option
                                        v-for="machine of machines"
                                        :key="machine.id"
                                        :value="machine.id"
                                        :selected="activity.machineId === machine.id"
                                    >
                                        {{ getMachineName(machine.id) }}
                                    </option>
                                </select>
                            </td>

                            <editable-cell
                                :value="parseInt(activity.quantityProduced)"
                                :display-value="parseInt(activity.quantityProduced)"
                                input-type="number"
                                :index="index"
                                @update:value="updateValue(activity, 'quantityProduced', $event)"
                            ></editable-cell>
                            <editable-cell
                                :value="parseFloat(activity.quantityBorra).toFixed(2)"
                                :display-value="parseFloat(activity.quantityBorra).toFixed(2)"
                                input-type="number"
                                :index="index"
                                @update:value="updateValue(activity, 'quantityBorra', $event)"
                            >
                            </editable-cell>
                            <editable-cell
                                :value="parseFloat(activity.quantityRefugo).toFixed(2)"
                                :display-value="parseFloat(activity.quantityRefugo).toFixed(2)"
                                input-type="number"
                                :index="index"
                                @update:value="updateValue(activity, 'quantityRefugo', $event)"
                            ></editable-cell>

                            <td
                                @click="toggleTeamDropdown(activity)"
                                class="clicavel"
                                @focusout="handleFocusOut(activity)"
                                tabindex="0"
                            >
                                <div>
                                    <div>{{ parseTeamMembersName(activity) }}</div>
                                    <div class="dropdown" @click.stop>
                                        <div class="dropdown-content" v-show="activity.isDropdownVisible">
                                            <label
                                                v-for="employee of injectionEmployees"
                                                :key="employee.id"
                                                @click="selectEmployee(activity, employee)"
                                                :value="employee"
                                                class="clicavel"
                                                :class="isEmployeeOnTeam(activity, employee) ? 'bg-blue' : 'bg-yellow'"
                                            >
                                                {{ employee.fullName }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="selectedOpId && !injectionOrder.isFinished" class="alinha-direita">
                    <button @click="finishOp">Finalizar OP</button>
                </div>
                <div v-if="selectedOpId && injectionOrder.isFinished" class="alinha-direita">
                    <button @click="reopenOp">Reabrir OP</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import NotificationMessenger from "@/components/NotificationMessenger.vue";
import { EditarApontamentoDatasource } from "@/data/datasource/ApontamentoDatasource";
import EditableCell from "@/components/EditableCell.vue";
import { sso } from "roboflex-thalamus-sso-lib";
import { InjectionActivityModel } from "@/data/model/InjectionActivityModel";

const dataSource = new EditarApontamentoDatasource();

export default {
    data() {
        return {
            notificationMessage: "",
            ops: [],
            machines: [],
            services: [],
            injectionEmployees: [],
            selectedOpId: 0,
            selectedEmployees: [],
            injectionOrder: { productName: "" },
            activities: [],
            childComponent: "component",
            usuario: null,
            isLoading: true,
        };
    },
    components: {
        NotificationMessenger,
        EditableCell,
    },
    async created() {
        this.usuario = sso.getUsuarioLogado();
        await this.fetchInitialData();
    },

    methods: {
        async fetchInitialData() {
            this.isLoading = true;
            this.ops = await dataSource.getActiveOps();
            this.machines = await dataSource.getMachines();
            this.services = await dataSource.getServicesType();
            this.injectionEmployees = await dataSource.getInjetionEmployees();
            this.isLoading = false;
        },

        clearSelection() {
            this.selectedOpId = null;
            this.injectionOrder = { productName: "" };
            this.activities = [];
        },

        async getOpData(opId) {
            this.clearSelection();
            if (opId == "") return;
            this.isLoading = true;

            this.selectedOpId = opId;
            this.injectionOrder = await dataSource.getOpDetails(opId);
            this.activities = await dataSource.getActivities(opId);
            this.isLoading = false;
        },

        parseDate(date, withHour) {
            if (date != null) {
                if (withHour) {
                    date = new Date(date).toLocaleString();
                } else {
                    date = new Date(date).toLocaleDateString();
                }
            } else {
                date = "-";
            }
            return date;
        },

        getMachineName(machineId) {
            let machine = this.machines.find((machine) => machine.id === machineId);
            return `${machine.name} ${machine.orderNum}`;
        },

        getServiceType(serviceId) {
            let service = this.services.find((service) => service.id === serviceId);
            return service.description;
        },

        /// Esse método é chamado pelo EditableCell quando emite um novo estado.
        async updateValue(activity, field, value) {
            /// Aqui a activity(o próprio trabalho/apontamento) recebe no campo alterado o novo valor
            activity[field] = value;

            let response = await dataSource.updateField(activity);

            if (response instanceof InjectionActivityModel) {
                this.notificationMessage = "Dado atualizado";
                this.$refs.notification.show();
            } else {
                this.notificationMessage = `Ocorreu um erro ao atualizar os dados,\ntente novamente mais tarde.\nError: ${response}`;
                this.$refs.notification.show();
            }
        },

        async finishOp() {
            var opId = this.selectedOpId;
            try {
                let response = await dataSource.finishOp(opId);
                this.ops = await dataSource.getActiveOps();

                this.notificationMessage = response;
                this.clearSelection();
            } catch (e) {
                this.notificationMessage = "Ocorreu um erro ao finalizar OP!";
                this.$refs.notification.show();
            }
        },

        async reopenOp() {
            var opId = this.selectedOpId;
            try {
                let response = await dataSource.reopenOp(opId);
                this.ops = await dataSource.getActiveOps();

                this.notificationMessage = response;
                this.clearSelection();
            } catch (e) {
                this.notificationMessage = "Ocorreu um erro ao finalizar OP!";
                this.$refs.notification.show();
            }
        },

        parseTeamMembersName(activity) {
            let employeesNames = [];
            let concatenatedNames = "";

            activity.team.forEach((element) => {
                var name = element.fullName.split(" ");
                employeesNames.push(`${name[0]} ${name[1] ?? ""}`);
            });

            for (var i = 0; i < employeesNames.length; i++) {
                if (employeesNames.length > 1 && i === employeesNames.length - 2) {
                    concatenatedNames += `${employeesNames[i]} e `;
                } else if (i === employeesNames.length - 1) {
                    concatenatedNames += `${employeesNames[i]}.`;
                } else {
                    concatenatedNames += `${employeesNames[i]}, `;
                }
            }
            return concatenatedNames;
        },

        toggleTeamDropdown(activity) {
            activity.isDropdownVisible = !activity.isDropdownVisible;
        },

        selectEmployee(activity, employee) {
            let isOnTeam = this.isEmployeeOnTeam(activity, employee);

            if (isOnTeam) {
                activity.team = activity.team.filter((ele) => !(ele.id === employee.id));
            } else {
                activity.team.push(employee);
            }
        },

        isEmployeeOnTeam(activity, employee) {
            let isOnTeam = activity.team.some((ele) => ele.id === employee.id);
            return isOnTeam;
        },

        async handleFocusOut(activity) {
            activity.isDropdownVisible = false;

            let response = await dataSource.updateField(activity);

            if (response instanceof InjectionActivityModel) {
                this.notificationMessage = "Dado atualizado";
                this.$refs.notification.show();
            } else {
                this.notificationMessage = `Ocorreu um erro ao atualizar os dados,\ntente novamente mais tarde.\nError: ${response}`;
                this.$refs.notification.show();
            }
        },
    },

    mounted() {
        document.addEventListener("mouseup", this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener("mouseup", this.handleClickOutside);
    },
};
</script>

<style scoped>
td {
    width: 500px;
}

select {
    border-radius: 10px 10px 0px 0px;
}

option {
    font-size: medium;
    padding: 20px;
    height: 40px;
}

.bg-blue {
    background-color: var(--cor-primaria-media);
}

.bg-yellow {
    background-color: white;
}

.dropdown {
    position: absolute;
}

.dropdown-content {
    z-index: 3;
    position: absolute;
    left: -35px;
    bottom: 45px;
    background-color: #f9f9f9;
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    height: 250px;
    overflow-y: scroll;
    border-radius: 10px 10px 0px 0px;
}

.dropdown-content::-webkit-scrollbar {
    display: none;
}

.dropdown-content label {
    padding: 12px 16px;
    display: block;
}
</style>
