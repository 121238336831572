import { api } from "roboflex-thalamus-request-handler/dist";
import { ProductModel } from "../model/ProductModel";

export default class InjectionProductDataSource {
    async getProducts() {
        // const rawData = await api.get("ENDPOINT");
        const rawData = MockData.products;
        const productList = [];


        for (var ele of rawData) {
            productList.push(ProductModel.fromJson(ele));
        }

        return productList;
    }

    async registerParam(product, qt) {
        const content = { produto_cod: product.code, qt_injecao: qt };
        const response = await api.post("ENDPOINT", content);

        console.log(response);
    }

    async getProductsWithParam() {
        // const rawData = await api.get("ENDPOINT");
        const rawData = MockData.injectionProducts;
        const productList = [];


        for (var ele of rawData) {
            productList.push(ProductModel.fromJson(ele));
        }

        return productList;
    }

    async deleteParam(product) {
        const content = { "produto_cod": product.code };
        await api.delete("ENDPOINT", content);
    }

    async editQuantity(product, newValue){
        const content = {"produto_cod": product.code, "qt_injecao": newValue}
        await api.patch("ENDPOINT", content)
    }
}

class MockData {
    static injectionProducts = [
        {
            id: 432,
            produto_cod: 23125315,
            desc: "Fundo X110",
            qt_injecao: 2,
        },
        {
            id: 151,
            produto_cod: 243454235,
            desc: "Case XPTO",
            qt_injecao: 1,
        },
        {
            id: 422,
            produto_cod: 42113134,
            desc: "Base quadrada",
            qt_injecao: 4,
        },
    ];

    static products = [
        {
            id: 321,
            produto_cod: 23125315,
            desc: "Fundo X110",
        },
        {
            id: 212,
            produto_cod: 243235,
            desc: "Case XPTO",
        },
        {
            id: 123,
            produto_cod: 42113134,
            desc: "Base quadrada",
        },
        {
            id: 322,
            produto_cod: 31651632,
            desc: "Braço C",
        },
        {
            id: 187,
            produto_cod: 16514651,
            desc: "Frontal T220",
        },
    ];


}
