<template>
    <section>
        <NotificationMessenger ref="notification" :message="notificationMessage" />

        <div class="titulo">
            <div class="margem container">
                <h2>Indicadores da Injeção</h2>
            </div>
        </div>

        <div class="margem container">
            <div class="bloco margem">
                <SelectableTags title="Filtrar por:" v-model="activeFilter" :onSelect="setFilter" :options="filters" />

                <div class="margem container linha date-line">
                    <SingleSelectDropdown
                        :title="dropdownTitle"
                        :options="labeledOptions"
                        v-model="optionSelected"
                        style="flex-grow: 2"
                    />
                    <!-- Linha para selecionar data de busca -->
                    <div class="coluna alinha-centro">
                        <div>
                            <h3 class="alinha-centro">Intervalo de busca</h3>
                            <div class="linha alinha-v">
                                <div>De</div>
                                <input type="date" v-model="startDt" />
                                até
                                <input type="date" v-model="endDt" />
                            </div>
                        </div>
                    </div>
                </div>
                <button class="search-btn" @click="buildGraphs">Buscar</button>
            </div>

            <div class="margem bloco container">
                <h3 class="alinha-centro">{{ getTitle() }}</h3>
                <div v-if="isLoading == true" class="loading"></div>

                <component :is="currentGraph" v-bind="graphComponentProps" />
            </div>
        </div>
    </section>
</template>

<script>
import { SingleSelectDropdown } from "roboflex-thalamus-componentes-vue3-lib";
import { SelectableTags } from "roboflex-thalamus-componentes-vue3-lib";
import { EditarApontamentoDatasource } from "@/data/datasource/ApontamentoDatasource";
import InjectionProductDataSource from "@/data/datasource/InjectionProductDataSource";

import IndicadoresOp from "./IndicadoresOp.vue";
import IndicadoresProduto from "./IndicadoresProduto.vue";
import IndicadoresInjetora from "./IndicadoresInjetora.vue";
import NotificationMessenger from "@/components/NotificationMessenger.vue";

const productDataSource = new InjectionProductDataSource();
const opDataSource = new EditarApontamentoDatasource();

export default {
    components: {
        SelectableTags,
        SingleSelectDropdown,
        NotificationMessenger,
    },
    data() {
        return {
            notificationMessage: "",
            filters: [
                { label: "Produto", value: "produto" },
                { label: "Injetora", value: "injetora" },
                { label: "Op", value: "op" },
            ],
            activeFilter: "produto",
            graphComponentProps: {},
            optionSelected: null,
            options: {},
            startDt: null,
            endDt: null,
            currentGraph: null,
            isLoading: false,
        };
    },
    methods: {
        setFilter(opt) {
            console.log(opt);
            this.currentGraph = null;
            this.optionSelected = null;
        },

        buildGraphs() {
            if (this.hasNullValue()) {
                this.$refs.notification.show();
                this.currentGraph = null;
                this.graphComponentProps = null;
                return;
            } else {
                console.log(this.startDt);
                this.isLoading = true;
                setTimeout(() => {
                    this.graphComponentProps = { startDt: this.startDt, endDt: this.endDt, id: this.optionSelected.id };
                    if (this.activeFilter == "op") {
                        this.currentGraph = IndicadoresOp;
                    } else if (this.activeFilter == "produto") {
                        this.currentGraph = IndicadoresProduto;
                    } else {
                        this.currentGraph = IndicadoresInjetora;
                    }

                    this.isLoading = false;
                }, 500);
            }
        },

        hasNullValue() {
            let hasNullValue;

            if (this.startDt == null || this.startDt == "") {
                this.notificationMessage = "Selecione uma data inicial";
                hasNullValue = true;
            } else if (this.endDt == null || this.endDt == "") {
                this.notificationMessage = "Selecione uma data final";
                hasNullValue = true;
            } else if (this.optionSelected == null || this.optionSelected == "") {
                this.notificationMessage = `Selecione um(a) ${this.activeFilter}`;
                hasNullValue = true;
            } else {
                hasNullValue = false;
            }

            return hasNullValue;
        },

        async fetchInitialData() {
            const [ops, products, machines] = await Promise.all([
                opDataSource.getActiveOps(),
                productDataSource.getProducts(),
                opDataSource.getMachines(),
            ]);

            this.options = {
                op: ops,
                produto: products,
                injetora: machines,
            };
        },

        getTitle() {
            let title = "";
            let subTitle = "";

            if (this.optionSelected) {
                subTitle += `${this.activeFilter.charAt(0).toUpperCase() + this.activeFilter.slice(1)} - `;

                if (this.optionSelected.name) {
                    subTitle += this.optionSelected.name;
                } else if (this.optionSelected.opNum) {
                    subTitle += this.optionSelected.opNum;
                }
            }

            title += `Indicadores por ${this.activeFilter}\n\n${subTitle}`;
            return title;
        },
    },

    computed: {
        dropdownTitle() {
            let msg = "Selecione ";
            if (this.activeFilter == "produto") {
                msg += "o ";
            } else {
                msg += "a ";
            }
            msg += this.activeFilter;
            return msg;
        },

        labeledOptions() {
            // Fallback
            if (!this.options || !this.options[this.activeFilter]) {
                return [];
            }
            let selectOptions = this.options[this.activeFilter];

            return selectOptions.map((option) => ({
                ...option,
                label: this.activeFilter === "op" ? option.opNum : option.name,
            }));
        },

        /// Retorna a view/componente de indicadores de acordo com o filtro ativado
        graphComponent() {
            if (this.activeFilter === "op") {
                return IndicadoresOp;
            } else if (this.activeFilter === "produto") {
                return IndicadoresProduto;
            } else {
                return IndicadoresInjetora;
            }
        },
    },
    created() {
        this.fetchInitialData();
    },
};
</script>

<style scoped>
h3 {
    text-align: center;
    white-space: pre-line;
}

.date-picker {
    width: 30%;
}

.search-btn {
    display: flex;
    justify-self: end;
}

.date-line {
    justify-content: space-between;
}

.material-symbols-outlined {
    cursor: pointer;
    color: var(--cor-erro);
}

.icones-datas {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
}
</style>
