<template>
    <div v-if="visible" class="notification">
        {{ message }}
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        show() {
            if (this.message !== "") {
                this.visible = true;
                setTimeout(() => {
                    this.hide();
                }, 2500);
            }
        },
        hide() {
            this.visible = false;
        },
    },
    watch: {
        message(newValue) {
            if (newValue !== "") {
                this.show();
            }
        },
    },
};
</script>

<style scoped>
.notification {
    position: fixed;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
}
</style>
