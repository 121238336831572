<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Cadastro de parâmetros de injeção</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="bloco margem">
                <div class="margem container linha" style="justify-content: space-around">
                    <div style="flex-grow: 1">
                        <h3>Selecione o produto</h3>
                        <select v-model="productSelected">
                            <option value="" selected></option>
                            <option v-for="ele in products" :key="ele.id" :value="ele">
                                {{ ele.name }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <h3>Quantidade de peça por injeção</h3>
                        <input type="number" v-model="qtyPerInjection" />
                    </div>
                </div>
                <div class="margem">
                    <div class="linha">
                        <SingleSelectDropdown title="Materia prima" :options="addLabelsOnMaterial" />
                        <div>
                            <h3>Quantidade (g)</h3>
                            <input type="number" style="width: 130px; align-self: flex-end" />
                        </div>
                    </div>
                </div>
                <button style="display: flex; justify-self: end" v-on:click="setParam()">Cadastrar</button>
            </div>

            <div class="bloco margem">
                <div class="titulo">
                    <div class="margem container">
                        <h2>Produtos cadastrados</h2>
                    </div>
                </div>
                <div class="loading" v-if="isLoading"></div>
                <table class="tabela" v-if="!isLoading">
                    <tbody>
                        <tr>
                            <th>Código</th>
                            <th>Produto</th>
                            <th>Peça por injeção</th>
                            <th></th>
                        </tr>
                        <tr v-for="ele of injectionProducts" v-bind:key="ele.id">
                            <td>{{ ele.code }}</td>
                            <td>{{ ele.name }}</td>
                            <td>{{ ele.qtyPerInjection }}</td>
                            <td><div class="icone-delete" v-on:click="deleteParamProduct(ele)"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import InjectionProductDataSource from "@/data/datasource/InjectionProductDataSource";
import { SingleSelectDropdown } from "roboflex-thalamus-componentes-vue3-lib";

const dataSource = new InjectionProductDataSource();

export default {
    components: { SingleSelectDropdown },
    data() {
        return {
            products: [],
            injectionProducts: [],
            productSelected: null,
            isLoading: null,
            qtyPerInjection: null,
            materialOptions: null,
            materialUsedList: [{ id: 1, name: "abs" }],
        };
    },
    methods: {
        parseDate(date, withHour) {
            if (date != null) {
                if (withHour) {
                    date = new Date(date).toLocaleString().replace(",", " -");
                } else {
                    date = new Date(date).toLocaleDateString();
                }
            } else {
                date = "-";
            }
            return date;
        },

        async setParam() {
            if (this.qtyPerInjection == null || this.qtyPerInjection <= 0) return;

            // await dataSource.registerParam(this.productSelected, this.qtyPerInjection);
            console.log(this.productSelected);
            this.productSelected.qtyPerInjection = this.qtyPerInjection;
            this.injectionProducts.push(this.productSelected);
            this.clearSelection();
        },

        async deleteParamProduct(product) {
            // await dataSource.deleteParam(product)

            const index = this.injectionProducts.indexOf(product);
            if (index !== -1) {
                this.injectionProducts.splice(index, 1);
            }
        },

        clearSelection() {
            this.productSelected = null;
            this.qtyPerInjection = null;
        },
    },

    computed: {
        addLabelsOnMaterial() {
            // Fallback
            if (!this.materialOptions || !this.materialOptions) {
                return [];
            }
            console.log("aqui");

            return this.materialOptions.map((ele) => ({
                ...ele,
                label: ele.name,
            }));
        },
    },
    async created() {
        this.isLoading = true;
        this.products = await dataSource.getProducts();
        this.injectionProducts = await dataSource.getProductsWithParam();
        this.materialOptions = [
            { id: 1, name: "ABS" },
            { id: 2, name: "ABS reciclado" },
            { id: 3, name: "Nylon" },
        ];
        this.addLabelsOnMaterial();
        this.isLoading = false;
    },
};
</script>

<style scoped>
.icone-delete {
    visibility: hidden;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.151);
    border: 1px solid rgba(0, 0, 0, 0.034);
}

tr:hover .icone-delete {
    visibility: visible;
    cursor: pointer;
}
</style>
