<template>
    <div v-if="isLoading" class="loading"><div></div></div>
    <div v-if="!isLoading" class="grid-2">
        <div class="bloco">
            <h3 class="alinha-centro">Tempo médio de ciclo (s)</h3>
            <div v-if="isLoading" class="loading"><div></div></div>
            <div v-if="!isLoading" id="line-chart-wrapper">
                <LineChart :chart-data-prop="cycleTimeGraph" :options="chartOptions" />
            </div>
        </div>

        <div class="bloco">
            <h3 class="alinha-centro">Quantidade de produtos injetados (un)</h3>
            <div v-if="isLoading" class="loading"><div></div></div>
            <div v-if="!isLoading" id="line-chart-wrapper">
                <BarChart :chartDataProp="productionGraph" :options="chartOptions" />
            </div>
        </div>

        <div class="bloco">
            <h3 class="alinha-centro">Produção de borra/refugo (kg)</h3>
            <div v-if="isLoading" class="loading"><div></div></div>
            <div v-if="!isLoading" id="line-chart-wrapper">
                <BarChart :chart-data-prop="wasteGraph" :options="chartOptions" />
            </div>
        </div>

        <!-- <div class="bloco">
            <h3 class="alinha-centro">Tempo médio de ciclo</h3>
            <div v-if="isLoading" class="loading"><div></div></div>
            <div v-if="!isLoading" id="line-chart-wrapper">
                <LineChart :chart-data-prop="cycleTimeGraph" :options="chartOptions" />
            </div>
        </div> -->
    </div>
</template>

<script>
import IndicadoresProdutoDatasource from "@/data/datasource/IndicadoresProdutoDatasource";
import LineChart from "@/components/LineChart.vue";
import BarChart from "@/components/BarChart.vue";

const datasource = new IndicadoresProdutoDatasource();

export default {
    props: {
        id: { type: Number, required: true },
        startDt: { type: String, required: true },
        endDt: { type: String, required: true },
    },

    components: {
        LineChart,
        BarChart,
    },

    data() {
        return {
            cycleTimeGraph: {
                datasets: [],
            },
            wasteGraph: {},
            productionGraph: {},
            isLoading: false,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: "black",
                        font: {
                            weight: "bold",
                            size: 16,
                        },
                    },
                },
            },
        };
    },

    methods: {
        async fetchData() {
            this.isLoading = true;

            let cycleRawData = await datasource.getCycleTimeData(this.id, this.startDt, this.endDt);
            let productionData = await datasource.getProductionData(this.id, this.startDt, this.endDt);
            let wasteRawData = await datasource.getWasteData(this.id, this.startDt, this.endDt);

            this.cycleTimeGraph = this.extractData(this.cycleTimeGraph, cycleRawData, "data", [
                { displayLabel: "Tempo (s)", jsonKey: "tempo_ciclo", bgColor: "blue" },
            ]);

            this.wasteGraph = this.extractData(this.wasteGraph, wasteRawData, "data", [
                { displayLabel: "Borra (kg)", jsonKey: "borra_qt", bgColor: "red" },
                { displayLabel: "Refugo (kg)", jsonKey: "refugo_qt", bgColor: "#6969cf" },
            ]);

            this.productionGraph = this.extractData(this.productionGraph, productionData, "data", [
                { displayLabel: "Quantidade", jsonKey: "quantidade", bgColor: "orange" },
            ]);

            this.isLoading = false;
        },

        extractData(baseGraph, fullData, labelKey, dataKeys) {
            // Limpa os dados anteriores
            baseGraph.labels = [];
            baseGraph.datasets = dataKeys.map(() => ({ labels: [], data: [] }));

            // Percorre cada conjunto de dados retornados pela API
            fullData.forEach((element) => {
                // Adiciona o label ao array de labels
                baseGraph.labels.push(element[labelKey]);

                // Insere cada conjunto de dados em um dataset
                dataKeys.forEach((key, index) => {
                    baseGraph.datasets[index].label = dataKeys[index].displayLabel;
                    baseGraph.datasets[index].backgroundColor = dataKeys[index].bgColor;
                    baseGraph.datasets[index].data.push(element[key.jsonKey]);
                });
            });

            return baseGraph;
        },
    },

    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
h3 {
    margin: 12px 0px;
}

#line-chart-wrapper {
    height: 320px;
}
</style>
